import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// TODO: Replace your config code below!
const firebaseConfig = {
  apiKey: "AIzaSyDgHhavEvVJ2aCJdZVKAeCYWr7kNV74pXY",
  authDomain: "first-project-77e86.firebaseapp.com",
  projectId: "first-project-77e86",
  storageBucket: "first-project-77e86.appspot.com",
  messagingSenderId: "913488092297",
  appId: "1:913488092297:web:b9beebf5f53e09db15ba58",
};
// End config code

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const auth = getAuth(app);
const user = auth.currentUser;

export { db, auth, user };
