import Login from "./Login";

function NotLoggedIn() {
  return (
    <>
      <Login />
    </>
  );
}

export default NotLoggedIn;
